/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body,html {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auto-slider{
  animation: 60s autoSlider infinite linear;
}
.novaBlack{
  font-family: 'ProximaNova-Black';
}

.novaBold{
  font-family: 'ProximaNova-Bold';
}

  #contatoTitle:before {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #4D4D4F;
    margin-left: -35px;
    margin-top: 12px;
}


@font-face{
  font-family: 'ProximaNova-Regular';
  src: './fonts/ProximaNova-Regular.tff';
}
@font-face{
  font-family: 'ProximaNova-Black';
  src: './fonts/ProximaNova-Black.woff';
}
@font-face{
  font-family: 'ProximaNova-Bold';
  src: './fonts/ProximaNova-Bold.woff';
}

@layer utilities {
  .hover-animate-span {
    transition: all 1s ease-in-out;
  }
}
